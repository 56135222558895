import { type ClientLoaderFunctionArgs, useLoaderData } from '@remix-run/react';

import { PromptTemplateEditor } from '../components/PromptTemplate/PromptTemplateEditor';
import { AdminView } from '../pages/Admin/AdminView';
import { AdminToolkitNav } from '../pages/Admin/Toolkit';
import { apiService } from '../services/api-service';
import { tokenWithRedirect } from '../utils/router';

export const loader = async (action: ClientLoaderFunctionArgs) => {
  const { id } = action.params;
  if (!id) throw new Response('Not Found', { status: 404 });

  const resp = await tokenWithRedirect(
    () => apiService.promptTemplate.getTemplateById(id),
    action.request.url,
    { admin: true }
  );

  return resp.data;
};

export function Component() {
  const { promptTemplate } = useLoaderData<typeof loader>();
  return (
    <AdminView className='bg-library-2023-07 p-10 flex flex-col gap-10'>
      <AdminToolkitNav />

      <PromptTemplateEditor template={promptTemplate} initialEditing={false} />
    </AdminView>
  );
}
